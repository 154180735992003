.divSocialMidia{
    display: flex;
    gap: 0.8rem;
    justify-content: center;
    margin: 1rem 0;
}
.divSocialMidia svg{
width: 2rem;
cursor: pointer;
}
