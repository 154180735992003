.buttonComponent{
    background: #fff;
    padding: 8px;
    border: none;
    border-radius: 4px;
    min-width: 320px;
}
svg{
    background: none;
    margin-right: 0.3rem;
    width: 1rem;
}
@media screen and (max-width: 998px) {
    button{
        width: 80vw;
    }
}