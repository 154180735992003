.imgCoracao{
    max-width: 150px;
    width: 30%;
    margin-top: 20px;
}
aside{
    display: flex;
    gap: 22px;
    align-items: center;
    justify-content: center;
}
a{
    font-size: 42px;
    color: #000;
    text-decoration: none;
}
strong{
    align-items: center;
    justify-content: center;
    display: flex;
}
.principalStrong{
    font-size: 20px;
    margin-bottom: -4px;
}
span{
    align-items: center;
    justify-content: center;
    display: flex;
}
.divTxtCampanha{
    display: grid;
    align-items: center;
    justify-content: center;
    
}
.imgCampanha{
    width: 60%;
}
.divCompras{
    justify-content: center;
    display: grid;
    gap: 10px;
}
.divCompras strong{
    margin-top: 1rem;

}
/* POPUP */

    
    
    /* Animation property */
    
    
    /* Keyframes */
    @keyframes pulse {
      0% {
        background-color: #efbb03;
      }
      100% {
        background-color: #ffffff;
      }
    }
    
    .example{
      animation: pulse 1s infinite;
      padding: 8px;
border-radius: 4px;
border: none;
    }
    .divFooter{
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .divFooter p{
        font-size: 0.7rem;
        margin-bottom: 0;
    }